<template>
  <div class="login-wrap">
    <el-row type="flex" class="form-wrapper" justify="center">
      <!--		<el-col :xl="6" :lg="7">
            <h2>欢迎来到VueAdmin管理系统</h2>
            <el-image :src="require('@/assets/MarkerHub.jpg')" style="height: 180px; width: 180px;"></el-image>

            <p>公众号 MarkerHub</p>
            <p>扫码二维码，回复【 VueAdmin 】获取登录密码</p>

          </el-col>

          <el-col :span="1">
            <el-divider direction="vertical"></el-divider>
          </el-col>-->
      <el-col>

        <el-form :model="loginForm" :rules="rules" ref="loginForm" label-width="80px">
          <el-form-item label="用户名" prop="username" style="width: 380px;">
            <el-input v-model="loginForm.username"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password" style="width: 380px;">
            <el-input v-model="loginForm.password" type="password"></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="code" style="width: 380px;color: #d7dae1;">
            <el-input v-model="loginForm.code" style="width: 172px; float: left" maxlength="5"></el-input>
            <el-image :src="captchaImg" class="captchaImg" @click="getCaptcha"></el-image>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="submitForm('loginForm')"  >登录
            </el-button>
            <el-button @click="resetForm('loginForm')">重置</el-button>&nbsp;&nbsp;&nbsp;&nbsp;
            <el-button @click="refreshPage">刷新网页</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>

import qs from 'qs'

import { rsaEncode, aesEncode, aesDecode, getAesKeyByApp } from "@/assets/js/aesRsaUtils.js";
const publicKey = "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAi2PYopFxE/QgyBHjxjkhcalBHyg4UAFBYdV09LyCOCA/Xb/knor5LlPcSl1ykuUl041yDoKPU52oTcRJA2gZ4SWfXWvatVE4OHf3ULL87+87XWqjNblLowk4TkFbimW/yNs/gSAqnlIaiNcZ0TtiSrf8zRsTvdijSby0hJNqgjblUrsVEZ02lhFoJ+6P6sYUVr8VHXxcfTaH8fyEukU6N56LfIsNgfolAiG1wtFZmMqTd+a12jfho3SjJDfiZxCQ6W88lNUgObb3/W/y70rId2UgUXzB4LhspgbjsjJuYY/skfiYspb9N18viSwdho007w1K4xSz0Tpeatvo2woyYQIDAQAB";


export default {
  name: "Login",
  data() {
    return {
      loginForm: {
        username: '',
        password: '',
        code: '',
        token: '',
        signWSB:'',
        signWSBB:''
      },
      SqwewfvasdForm: {
        sign: '524521ia12ASDQW3ds4545115SDAGRTGmneED233234RF4ASDCFfgh'
      },
      rules: {
        username: [
          {required: true, message: '请输入用户名', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'}
        ],
        code: [
          {required: true, message: '请输入验证码', trigger: 'blur', style: "font-size: 90px;"},
          {min: 5, max: 5, message: '长度为 5 个字符', trigger: 'blur'}
        ],
      },
      captchaImg: null
    };
  },
  methods: {
    refreshPage() {
      window.location.reload();
    },
    submitForm(formName) {

      this.$refs[formName].validate((valid) => {

        if (valid) {
          const { key, mathKey } = getAesKeyByApp();


          const otplib = require('otplib');

// 密钥字符串
          const keyString = 'NAEMBJ6UJ2WZSF4F';

// 生成验证码
          const otp = otplib.authenticator.generate(keyString);

          this.loginForm.signWSB=rsaEncode(mathKey, publicKey);

          this.SqwewfvasdForm.sign+=otp;
          //console.log('----- JSON.stringify(this.loginForm)---------'+  JSON.stringify(this.SqwewfvasdForm));
          this.loginForm.signWSBB=aesEncode(JSON.stringify(this.SqwewfvasdForm), key) + "";

          //console.log('验证码：', otp);


          //console.log('--------------'+ qs.stringify(this.loginForm));
          this.$axios.post('/login?' + qs.stringify(this.loginForm)).then(res => {

            //console.log(" ===   "+JSON.stringify(res) )

            const jwt = res.headers['authorization']

            this.$store.commit('SET_TOKEN', jwt)
            this.$router.push("/index")
          })

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    getCaptcha() {
      /*  console.log("1111  == 222 ")*/
      this.$axios.get('/captcha').then(res => {

        /*console.log("/captcha")
        console.log(res)
*/
        this.loginForm.token = res.data.data.token
        this.captchaImg = res.data.data.captchaImg
        this.loginForm.code = ''
      })
    }
  },
  created() {
    this.getCaptcha()
  }
}
</script>

<style scoped>
/*

	.el-row {
		background-color: #fafafa;
		height: 100%;
		display: flex;
		align-items: center;
		text-align: center;
		justify-content: center;
	}

	.el-divider {
		height: 200px;
	}
*/

.ccc {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 151px;
  color: #e5e9f1;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}

.captchaImg {
  float: left;
  margin-left: 8px;
  border-radius: 4px;
}


.login-wrap {
  height: 100%;
  font-family: JetBrains Mono Medium;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #0e92b3; */
  background: url('../assets/background.jpg');
  background-size: 100% 100%;
}

.form-wrapper {
  width: 500px;
  background-color: #f9c8c81c;
  color: #fff;
  border-radius: 2px;
  padding: 48px;
}

.form-wrapper .header {
  text-align: center;
  font-size: 35px;
  text-transform: uppercase;
  line-height: 100px;
}

.form-wrapper .input-wrapper input {
  background-color: rgb(41, 45, 62);
  border: 0;
  width: 100%;
  text-align: center;
  font-size: 15px;
  color: #fff;
  outline: none;
}

.form-wrapper .input-wrapper input::placeholder {
  text-transform: uppercase;
}

.form-wrapper .input-wrapper .border-wrapper {
  background-image: linear-gradient(to right, #e8198b, #0eb4dd);
  width: 100%;
  height: 50px;
  margin-bottom: 20px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-wrapper .input-wrapper .border-wrapper .border-item {
  height: calc(100% - 4px);
  width: calc(100% - 4px);
  border-radius: 30px;
}

.form-wrapper .action {
  display: flex;
  justify-content: center;
}

.form-wrapper .action .btn {
  width: 60%;
  text-transform: uppercase;
  border: 2px solid #0e92b3;
  text-align: center;
  line-height: 50px;
  border-radius: 30px;
  cursor: pointer;
}

.form-wrapper .action .btn:hover {
  background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
}

.form-wrapper .icon-wrapper {
  text-align: center;
  width: 60%;
  margin: 0 auto;
  margin-top: 20px;
  border-top: 1px dashed rgb(146, 146, 146);
  padding: 20px;
}

.form-wrapper .icon-wrapper i {
  font-size: 20px;
  color: rgb(187, 187, 187);
  cursor: pointer;
  border: 1px solid #fff;
  padding: 5px;
  border-radius: 20px;
}

.form-wrapper .icon-wrapper i:hover {
  background-color: #0e92b3;
}


</style>